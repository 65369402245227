import * as React from "react"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import Paper from "@material-ui/core/Paper"

import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"

import Layout from "../../components/layout"
import Head from "../../components/head"

const useStyles = makeStyles(theme => ({
  heroContent: {
    padding: theme.spacing(15, 0, 12),

    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(9, 0, 4),
    },
  },

  tableWrap: {
    marginBottom: theme.spacing(5),
  },

  listLowerAlpha: {
    listStyle: "lower-alpha",
  },
}))

const ImmigrationLaw = () => {
  const classes = useStyles()

  return (
    <Layout>
      <Head title="Family Law" />
      <main>
        <div className={classes.heroContent}>
          <Container maxWidth="md">
            <Typography
              component="h1"
              variant="h3"
              align="center"
              color="textPrimary"
              gutterBottom
            >
              Immigration Law
            </Typography>

            <Typography component="p" paragraph>
              <strong>
                Migration law, and visa applications have become so technical
                and convoluted that it is highly recommended for you to obtain a
                skilled immigration law specialist to manage your migration
                application/s.
              </strong>
            </Typography>
            <Typography component="p" paragraph>
              My Golden Rules on migration are:
            </Typography>
            <Typography component="ol" paragraph>
              <Typography component="li">
                Immigration law is always under change. If you are eligible for
                a visa then apply now, do not wait. Be quick, be timely.
              </Typography>
              <Typography component="li">
                Be active and vigorous in your application for a visa. Find out
                what immigration law actually applies to you and make sure that
                you respond to all legal issues in your application.
              </Typography>
              <Typography component="li">
                There are so many technical and little rules in each visa
                application and you have got to take each rule seriously – work
                out what are the requirements at date of application, what are
                the requirements at date of decision.
              </Typography>
              <Typography component="li">
                Be thorough in your application. You must be fully prepared to
                present all your facts in the best possible manner.
              </Typography>
              <Typography component="li">
                Other than parent visas, all other visas are not assessed by a
                queue date on a first come first served basis. There are times
                when you have to be active and complain to the Department of
                Home Affairs where your case is fully completed but is taking
                unduly long to process.
              </Typography>
            </Typography>
            <Typography component="p" paragraph>
              The major visa groups include:
            </Typography>
            <TableContainer component={Paper} className={classes.tableWrap}>
              <Table
                sx={{ minWidth: 650 }}
                aria-label="major visa groups table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Visa Category</TableCell>
                    <TableCell>Visas</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow
                    key="family"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Family visas
                    </TableCell>
                    <TableCell>
                      spouse, prospective spouse, parent visas, child visas,
                      aged dependent relative, carer, last remaining relative
                    </TableCell>
                  </TableRow>
                  <TableRow
                    key="skilled"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Skilled visas
                    </TableCell>
                    <TableCell>
                      independent skilled visa, State/Territory nominated
                      skilled visas, regional skilled visas, NZ citizen skilled
                      visa, global talent visas
                    </TableCell>
                  </TableRow>
                  <TableRow
                    key="Employer"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Employer sponsored visas
                    </TableCell>
                    <TableCell>
                      temporary skilled shortage visa (subclass 482), employer
                      nomination permanent visas (subclass 186/187)
                    </TableCell>
                  </TableRow>
                  <TableRow
                    key="Business"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Business visas
                    </TableCell>
                    <TableCell>business owner visas, investor visas</TableCell>
                  </TableRow>
                  <TableRow
                    key="Refugee"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Refugee visas
                    </TableCell>
                    <TableCell>
                      protection visas, special humanitarian visas
                    </TableCell>
                  </TableRow>

                  <TableRow
                    key="Temporary"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Short term temporary visas
                    </TableCell>
                    <TableCell>
                      visitors, students, religious workers, entertainers,
                      sports persons, training visas.
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>

            <Typography
              component="h2"
              variant="h4"
              color="textPrimary"
              gutterBottom
            >
              Partner visas
            </Typography>
            <Typography component="p" paragraph>
              Visas available include:
            </Typography>
            <Typography component="ul" paragraph>
              <Typography component="li">Prospective spouse</Typography>
              <Typography component="li">Spouse</Typography>
              <Typography component="li">
                De Facto spouse (including same – sex relationships)
              </Typography>
            </Typography>
            <Typography
              component="h3"
              variant="h5"
              color="textPrimary"
              gutterBottom
            >
              Prospective spouse visas
            </Typography>
            <Typography component="p" paragraph>
              Prospective spouse visas can only be lodged overseas and is a
              short term temporary visa, generally for 10 months to allow the
              Applicant sufficient time to enter Australia, marry and lodge an
              on-shore spouse visa application in Australia. This is therefore a
              three step process: from the prospective spouse visa (subclass
              300) to the temporary spouse visa (subclass 820) and then the
              permanent spouse visa (subclass 801).
            </Typography>
            <Typography component="p" paragraph>
              Both parties are required to have met each other face to face
              before the application is lodged.
            </Typography>
            <Typography component="p" paragraph>
              There is a requirement for the Applicant to provide a Notice of
              Intention to Marry (NOIM)in the application. This document can be
              obtained from a Marriage Celebrant or at the Registry of Births
              Deaths and Marriages.
            </Typography>

            <Typography
              component="h3"
              variant="h5"
              color="textPrimary"
              gutterBottom
            >
              Spouse visas
            </Typography>
            <Typography component="p" paragraph>
              Spouse visas can be applied off shore (subclass 309 & 100 visas)
              or onshore (subclass 820 & 801 visas). If the spouse visa
              application is lodged on-shore, the Applicant automatically
              obtains a processing visa called a Bridging visa that entitles the
              Applicant to remain in Australia until their application is
              decided.
            </Typography>
            <Typography component="p" paragraph>
              The term “spouse” is defined in Section 5F of the Migration Act,
              which requires a spouse to be in a “married relationship”. A
              “married relationship” requires the two persons to have a valid
              marriage, a mutual commitment to a shared life as husband and wife
              to the exclusion of all others; the relationship between them is
              genuine and continuing; they live together or do not live
              separately and apart on a permanent basis.
            </Typography>
            <Typography component="p" paragraph>
              The decision maker is required to consider all circumstances of
              the “married relationship” including the financial aspects of the
              relationship, the nature of the household, the social aspects of
              the relationship and the nature of the persons’ commitment to each
              other (see Regulation 1.15A).
            </Typography>
            <Typography
              component="h3"
              variant="h5"
              color="textPrimary"
              gutterBottom
            >
              De facto spouse visas (includes same-sex relationships)
            </Typography>
            <Typography component="p" paragraph>
              A de facto relationship is defined as a relationship between two
              persons who are not in a “marriage relationship” but:
            </Typography>
            <Typography
              component="ul"
              paragraph
              className={classes.listLowerAlpha}
            >
              <Typography component="li">
                have a mutual commitment to a shared life to the exclusion of
                all others; and
              </Typography>
              <Typography component="li">
                the relationship between them is genuine and continuing; and
              </Typography>
              <Typography component="li">
                they live together or do not live separately and apart on a
                permanent basis; and
              </Typography>
              <Typography component="li">
                they are not family members such as parent, child, sibling
                including adoptive parent, adoptive child, and adoptive sibling.
              </Typography>
            </Typography>
            <Typography component="p" paragraph>
              The decision maker is required to consider all circumstances of
              the de facto (including same-sex) relationship including the
              financial aspects of the relationship, the nature of the
              household, the social aspects of the relationship and the nature
              of the persons’ commitment to each other (see Regulation 1.09A).
            </Typography>
            <Typography component="p" paragraph>
              Length of relationship: Evidence of a de facto relationship is
              generally proof of living together for 12 months as at date of
              application. There are waiver provisions where there are:
              compelling reasons for the waiver e.g. where there are children in
              the relationship; or the government on the home country does not
              allow for the couple to live together; or where the applicant is
              the holder of a permanent humanitarian visa; or alternatively
              where the relationship is registered in the State/Territory.
            </Typography>
            <Typography component="p" paragraph>
              A de facto couple can register their de facto relationship with
              the NSW Registry of the Births, Deaths, and Marriages in the
              Sydney or Parramatta office and obtain a Relationship Certificate.
              However, while the Migration Act recognises a de facto
              relationship while one or both of the Applicants are married but
              permanently separated from their previous spouses, the Registry of
              Births Deaths and Marriage would require the parties to be
              formally divorced prior to the registration of their de facto
              relationship certificate.
            </Typography>

            <Typography
              component="h3"
              variant="h5"
              color="textPrimary"
              gutterBottom
            >
              Complications in partner visa applications
            </Typography>

            <Typography component="p" paragraph>
              Some overseas posts are particularly concerned where the parties
              do not conduct a traditional marriage and refuse applications just
              on that ground alone. The rational given is that marriage is very
              important and if the country’s traditions are not observed then
              the marriage may not be genuine. It is important therefore when a
              couple choose not to follow a traditional wedding in the home
              country that this is carefully explained.
            </Typography>

            <Typography component="p" paragraph>
              As a general rule, there is family support for the wedding of the
              two parties. This topic should be covered in the application and
              also an explanation as to why close family members did not attend
              the wedding.
            </Typography>

            <Typography component="p" paragraph>
              There are also other partner visa complications of:
            </Typography>

            <Typography component="ul" paragraph>
              <Typography component="li">
                End of a relationship due to family violence provisions or where
                there is a child in the relationship, or the Sponsor dies before
                visa grant
              </Typography>
              <Typography component="li">
                Lodging a partner visa application out of time – commonly known
                as Section 48 and Schedule 3 requirements
              </Typography>

              <Typography component="li">
                Sponsorship limitations to two offshore marriage and exemptions
                to this rule
              </Typography>
              <Typography component="li">
                5 years bar from the sponsor lodging a second or additional
                sponsorship for another partner and exemptions to this rule.
              </Typography>
              <Typography component="li">
                Character issues for either Applicant or Sponsor
              </Typography>
            </Typography>

            <Typography
              component="h2"
              variant="h4"
              color="textPrimary"
              gutterBottom
            >
              New Zealand citizens
            </Typography>
            <Typography component="p" paragraph>
              New Zealand citizens who entered Australia prior to 2 April 2001
              are considered to be Australian permanent residents. However, New
              Zealand citizens who entered Australia after that date are
              considered to be temporary residents on an indefinite basis under
              the subclass 444 visa.
            </Typography>
            <Typography component="p" paragraph>
              New Zealand citizens who entered Australia before 19 February 2016
              are eligible to apply for a specific skilled visa subclass 189
              where they can show residency of 5 years in Australia and taxable
              income over the threshold requirement of $53,900 for 3 out of the
              last 5 years immediately before application.
            </Typography>

            <Typography
              component="h2"
              variant="h4"
              color="textPrimary"
              gutterBottom
            >
              Special provisions for absorbed persons and persons born in
              Australia prior to 20 August 1986
            </Typography>
            <Typography component="p" paragraph>
              Where a person is unlawful and meets the requirements of section
              34 of the Migration Act, that is to have resided in Australia
              prior to 1 April 1979, the person may be considered to be absorbed
              in Australia and is considered an Australian permanent resident.
            </Typography>
            <Typography component="p" paragraph>
              Prior to 20 August 1986, a person born in Australia was an
              automatic Australian citizen regardless of their parents’
              nationality. Since 20 August 1986, a child born in Australia takes
              on the nationality of their parents.
            </Typography>
            <Typography component="p" paragraph>
              Also note that a child born in Australia, and lives in Australia
              for 10 years from date of birth, is eligible to apply for
              Australian citizenship. A child abandoned in Australia is also
              eligible for Australian citizenship.
            </Typography>

            <Typography
              component="h2"
              variant="h4"
              color="textPrimary"
              gutterBottom
            >
              Visa Cancellations – for temporary and permanent resident visa
              holders
            </Typography>
            <Typography component="p" paragraph>
              Visas can be granted and they can also be cancelled. Cancelling a
              temporary or permanent resident visa can be very quick and very
              easy.
            </Typography>
            <Typography component="p" paragraph>
              There are a wide variety of cancellation powers under the
              Migration Act. Visas can be cancelled on grounds of providing
              incorrect information (PIC 4020), or not meeting visa condition
              requirements (see a series of cancellation powers under section
              116 of the Migration Act) or where a person is considered not to
              be of good character (see section 501 of the Migration Act). Visas
              can be cancelled where the Applicant is on-shore or also where the
              Applicant is travelling off-shore (see section 128 of the
              Migration Act).
            </Typography>
            <Typography component="p" paragraph>
              There is a mandatory cancellation provision under Section 503A of
              the Migration Act. This states that where you have a “substantial”
              criminal record, as defined by legislation, including being
              sentenced to imprisonment for an aggregate of 12 months or more,
              and you are serving a full time prison sentence then your visa
              must be cancelled. You can apply for revocation of the decision to
              cancel your visa.
            </Typography>
            <Typography component="p" paragraph>
              In the past financial year ending 30 June 2021, the Department
              cancelled 28 visas due to the visa holder’s driving offences. It
              is true.
            </Typography>
          </Container>
        </div>
      </main>
    </Layout>
  )
}

export default ImmigrationLaw
